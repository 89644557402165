<template>
  <div class="dialog-form">
    <el-dialog v-if="isShow" v-model="isShow" title="更新用户基本信息" width="30%">
      <el-form
        label-position="left"
        :rules="rules"
        ref="ruleFormRef"
        :model="ruleForm"
        label-width="80px"
        class="demo-ruleForm"
      >
        <el-form-item label="姓名:" prop="realName">
          <el-input v-model="ruleForm.realName" placeholder="姓名" />
        </el-form-item>
        <el-form-item label="选择生日:" prop="birthDay">
          <el-date-picker
            @change="handleDateChange"
            v-model="ruleForm.birthDay"
            type="date"
            placeholder="选择生日"
          />
        </el-form-item>
        <el-form-item label="对应星座:" prop="name">
          <el-input readonly v-model="ruleForm.contellation" placeholder="星座" />
        </el-form-item>
        <el-form-item label="所在门店:" prop="shopId">
          <el-select
            clearable
            v-model="ruleForm.shopId"
            class="m-2"
            placeholder="所属门店"
            size="large"
          >
            <el-option
              v-for="item in shopList"
              :key="item.shopId"
              :label="item.shopName"
              :value="item.shopId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户来源:" prop="customerSourceId">
          <el-select
            clearable
            v-model="ruleForm.customerSourceId"
            class="m-2"
            placeholder="客户来源"
            size="large"
          >
            <el-option
              v-for="item in sourceList"
              :key="item.customerSourceId"
              :label="item.customerSourceName"
              :value="item.customerSourceId"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button size="small" @click="saveInfoClick(ruleFormRef)" type="primary"
          >保存</el-button
        >
      </template>
    </el-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed } from 'vue'
import UploadImg from '@/components/page-upload/upload-img/upload-img.vue'
import { COMPANY_ID } from '@/common/constant'
import { useStore } from 'vuex'
import { useState } from '@/hooks'
import { rankProps, genderList } from './config'
import dayjs from 'dayjs'
import { getUserXingzuo } from '@/utils/xingzuo'
import { editUserInfo } from '@/service/main/customer'
import { tips } from '@/utils/tips'
import { rules } from './config'
import { customerSourceList,changeCustomerSource } from '@/service/main/customer/index.js'
import {
  getAllShopList
} from '@/service/main/shop'
export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    userInfo: {
      type: Number,
      default: -1
    }
  },
  components: {
    UploadImg

  },
  emits: ['changeDialogVisible', 'refreshUserInfo'],
  setup(props, { emit }) {
    const store = useStore()
    const isShow = ref(props.dialogVisible)

    watch(() => props.dialogVisible, (newValue) => {
      isShow.value = newValue
    }, {
      deep: true
    })

    watch(isShow, (newValue) => {
      emit('changeDialogVisible', newValue)
    })

    const ruleForm = reactive({
      birthDay: '',
      userId: props.userInfo.userId,
      contellation: '',
      shopId: '',
      realName: '',
      customerSourceId:''
    })

    watch(() => props.userInfo, (n) => {
      if (n != null) {
        ruleForm.birthDay = n.birthday
        ruleForm.contellation = n.xingzuo
        ruleForm.shopId = n.shopId?n.shopId:''
        ruleForm.realName = n.uname
        ruleForm.customerSourceId = n.customerSourceId
      }
    }, {
      deep: true
    })

    const saveInfoClick = async (formEl = undefined) => {
      if (!formEl) return
      await formEl.validate(async (valid, fields) => {
        if (valid) {
          ruleForm.birthDay = dayjs(ruleForm.birthDay).format('YYYY-MM-DD')
          const res = await editUserInfo({ ...ruleForm })
          if (res.code == 0) {
            const res1 = await changeCustomerSource({ id:ruleForm.userId,customerSourceId:ruleForm.customerSourceId })
            if (res1.code == 0) {
              emit('refreshUserInfo')
              tips(res)
              isShow.value = false
            }
          }
          // console.log('submit!')
        } else {
          // console.log('error submit!', fields)
        }
      })
    }
    const sourceList = ref([])
    const getDataList = async () => {
        let res = await customerSourceList()
        sourceList.value = res.data
    }

    const handleDateChange = (data) => {
      let d = new Date(data)
      let month = d.getMonth() + 1
      let day = d.getDate()
      ruleForm.contellation = getUserXingzuo(month, day) + '座'
    }


    const shopList = ref([])
    const ruleFormRef = ref()

    const getShopList = async () =>{
      let params = {
        current:1,
        size:-1
      }
      let res = await getAllShopList(params)
      shopList.value = res.data.list
    }

    const initPage = () => {
      getShopList()
      getDataList()
      ruleForm.birthDay = props.userInfo.birthday
      ruleForm.contellation = props.userInfo.xingzuo
      ruleForm.shopId = props.userInfo.shopId?props.userInfo.shopId:''
      ruleForm.realName = props.userInfo.uname
      ruleForm.customerSourceId = props.userInfo.customerSourceId
    }
    initPage()


    return {
      ruleFormRef,
      shopList,
      isShow,
      COMPANY_ID,
      rankProps,
      genderList,
      dayjs,
      ruleForm,
      handleDateChange,
      saveInfoClick,
      rules,
      sourceList


    }

  }
})
</script>

<style scoped lang="less">
.dialog-form {
  &:deep(.el-form) {
    .el-input,
    .el-select {
      width: 100%;
    }
  }

  &:deep(.el-form-item__label) {
    font-size: 12px;
  }

  .use-time {
    line-height: 45px;
    background-color: rgb(224, 241, 240);
    padding: 0 10px;
  }

  .handsel {
    .title {
      margin: 20px 0 10px 0;
      color: rgb(181, 181, 181);
    }

    .item {
      display: flex;
      justify-content: space-between;
      line-height: 40px;
    }
  }

  &:deep(.el-scrollbar) {
    padding-right: 20px;
  }
}
</style>
