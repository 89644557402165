<template>
  <div class="base-info-wrap">
    <div class="top">
      <div class="avatar">
        <template
          v-if="userDetail.userinfo && userDetail.userinfo.userAva != 'default_ava.png'"
        >
          <img :src="userDetail.userinfo.userAva" alt="" />
        </template>
        <template v-else>
          <el-icon>
            <User />
          </el-icon>
        </template>
      </div>
      <div class="name">
        <div v-if="userDetail.userinfo" class="nick-name">
          {{ userDetail.userinfo.userRealname }}({{ userDetail.userinfo.userNick }})
        </div>
        <div v-if="userDetail.userinfo" class="grade">
          {{ userDetail.userinfo.levelName }}(成长值{{ userDetail.userinfo.points }})
        </div>
      </div>
    </div>
    <el-divider></el-divider>
    <div class="tag tag-wrap">
      <div class="title">
        <div class="left">标签</div>
        <div>
          <span @click="editTagClick" class="right">标签管理</span>
        </div>
      </div>
      <div class="tag-item">
        <div class="left">已打标签：</div>
        <div v-if="userDetail.userinfo" class="right">
          <template v-if="userDetail.userinfo.userTags != null">
            <template :key="item" v-for="item in userDetail.userinfo.userTags.split(',')">
              <span> {{ item }}</span>
            </template>
          </template>
          <template v-else>暂无标签</template>
        </div>
      </div>
    </div>
    <el-divider></el-divider>
    <div class="consume-info">
      <div class="title">
        <span class="left">消费画像</span>
        <!-- <span class="right">编辑</span> -->
      </div>
      <div class="consume-info-list">
        <div class="consume-info-item">
          <div class="left">余额</div>
          <div class="right">
            <span>
              {{ userDetail.userCash ? (userDetail.userCash / 100).toFixed(2) : 0 }}
            </span>
            <!-- <span @click="handleUpdateYue">更改余额</span> -->
          </div>
        </div>
        <div class="consume-info-item">
          <div class="left">累计消费金额</div>
          <div v-if="userDetail.payinfo" class="right">
            {{ userDetail.payinfo.payPrice ? (userDetail.payinfo.payPrice / 100).toFixed(2) : 0 }}
          </div>
        </div>
        <div class="consume-info-item">
          <div class="left">总消费次数</div>
          <div v-if="userDetail.payinfo" class="right">
            {{ userDetail.payinfo.payQty }}
          </div>
        </div>
        <div class="consume-info-item">
          <div class="left">最近消费时间</div>
          <div v-if="userDetail.latestPayInfo != null" class="right">
            {{ dayjs(userDetail.latestPayInfo).format("YYYY年MM月DD日") }}
          </div>
          <div v-else class="right">-</div>
        </div>
      </div>
    </div>
    <el-divider class="border1"></el-divider>
    <div class="base-info">
      <div class="title">
        <span class="left">基础信息</span>
        <span @click="editBaseInfoClick" class="right">编辑</span>
      </div>
      <div class="base-info-item">
        <div class="left">姓名：</div>
        <div v-if="userDetail.userinfo" class="right">
          {{ userDetail.userinfo.userRealname ?? "-" }}
        </div>
      </div>
      <div class="base-info-item">
        <div class="left">性别：</div>
        <div v-if="userDetail.userinfo" class="right">
          {{ userDetail.userinfo.gender == 1 ? "男" :userDetail.userinfo.gender == 2? "女":'' }}
        </div>
      </div>

      <div class="base-info-item">
        <div class="left">账号：</div>
        <div v-if="userDetail.userinfo" class="right">
          {{ userDetail.userinfo.userMobile }}
        </div>
      </div>
      <div class="base-info-item">
        <div class="left">客户编号：</div>
        <div v-if="userDetail.userinfo" class="right">
          {{ userDetail.userinfo.userNo }}
        </div>
      </div>
      <div class="base-info-item">
        <div class="left">生日：</div>
        <div v-if="userDetail.userinfo" class="right">
          {{ userDetail.userinfo.birthday ?? "-" }}
        </div>
      </div>
      <div class="base-info-item">
        <div class="left">星座：</div>
        <div v-if="userDetail.userinfo" class="right">
          {{ userDetail.userinfo.contellation ?? "-" }}
        </div>
      </div>
      <div class="base-info-item">
        <div class="left">归属门店：</div>
        <div class="right">{{ userDetail.shopName ?? "-" }}</div>
      </div>
      <div class="base-info-item">
        <div class="left">客户来源：</div>
        <div class="right">{{ userInfo.customerSourceName?userInfo.customerSourceName:'-' }}</div>
      </div>
      <div class="base-info-item">
        <div class="left">注册时间：</div>
        <div v-if="userDetail.userinfo" class="right">
          {{ userDetail.userinfo.createTime }}
        </div>
      </div>
    </div>
  </div>
  <editDialog
    v-if="editInfoDialogVisible"
    @refreshUserInfo="refreshUserInfo"
    :userInfo="userInfo"
    @changeDialogVisible="changeEditInfoDialogVisible"
    :dialogVisible="editInfoDialogVisible"
  ></editDialog>
  <tagDialog
    @refreshUserInfo="refreshUserInfo"
    :userInfo="userInfo"
    @changeDialogVisible="changeTagDialogVisible"
    :dialogVisible="editTagDialogVisible"
  >
  </tagDialog>
  <addTagDialog
    v-if="addTagDialogVisible"
    @refreshUserInfo="refreshUserInfo"
    :userInfo="userInfo"
    :dialogVisible="addTagDialogVisible"
    @changeDialogVisible="changeaddTagDialogVisible"
  ></addTagDialog>
  <updateyueDialog
    v-if="yueDialogVisible"
    @refreshUserInfo="refreshUserInfo"
    @changeDialogVisible="changeYueDialogVisible"
    :dialogVisible="yueDialogVisible"
  ></updateyueDialog>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, watch,onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import editDialog from '../../base-ui/edit-dialog/edit-dialog.vue'
import dayjs from 'dayjs'
import tagDialog from '../../base-ui/tag-dialog/tag-dialog'
import addTagDialog from '../../base-ui/add-tag-dialog/add-tag-dialog.vue'
import updateyueDialog from '../../base-ui/update-yu-e/edit-dialog.vue'
export default defineComponent({
  props: {

  },
  components: {
    editDialog,
    tagDialog,
    addTagDialog,
    updateyueDialog
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const userId = computed(() => route.query.userId)
    const userDetail = computed(() => store.state.customer.userDetail)
    let userInfo = reactive({
      userId: userId.value,
      birthday: '',
      xingzuo: '',
      shopId: '',
      tagList: '',
      uname: '',
      customerSourceName:'',
      customerSourceId:''
    })
    watch(userDetail, (n) => {
      if (n != null) {

        userInfo.birthday = n.userinfo && n.userinfo.birthday
        userInfo.xingzuo = n.userinfo && n.userinfo.contellation
        userInfo.shopId = n.userinfo && n.userinfo.shopId
        userInfo.tagList = n.userinfo && n.userinfo.userTags
        userInfo.uname = n.userinfo && n.userinfo.userRealname
        userInfo.customerSourceName = n.userinfo && n.userinfo.customerSourceName
        userInfo.customerSourceId = n.userinfo && n.userinfo.customerSourceId
      }
    }, {
      deep: true
    })

    const initPage = () => {
      store.dispatch('customer/getUsderDetailAction', userId.value)
    }
    initPage()

    const editInfoDialogVisible = ref(false)
    const editTagDialogVisible = ref(false)
    const changeEditInfoDialogVisible = flag => {
      editInfoDialogVisible.value = flag
    }

    const addTagDialogVisible = ref(false)
    const changeaddTagDialogVisible = flag => {
      addTagDialogVisible.value = flag
    }

    const yueDialogVisible = ref(false)
    const changeYueDialogVisible = flag => {
      // console.log(flag);
      yueDialogVisible.value = flag
    }

    const addTagClick = () => {
      addTagDialogVisible.value = true
    }

    const editTagClick = () => {
      editTagDialogVisible.value = true
    }

    const changeTagDialogVisible = flag => {
      editTagDialogVisible.value = flag
    }

    const editBaseInfoClick = () => {
      editInfoDialogVisible.value = true
    }

    const handleUpdateYue = () => {
      yueDialogVisible.value = true
    }

    const refreshUserInfo = () => {
      initPage()
    }
    onBeforeUnmount(() => {
      store.commit('customer/changeUserDetail', {})
    });

    return {
      changeYueDialogVisible,
      yueDialogVisible,
      handleUpdateYue,
      addTagClick,
      changeaddTagDialogVisible,
      refreshUserInfo,
      editBaseInfoClick,
      userDetail,
      dayjs,
      changeEditInfoDialogVisible,
      editInfoDialogVisible,
      editTagDialogVisible,
      changeTagDialogVisible,
      userId,
      userInfo,
      editTagClick,
      addTagDialogVisible
    }

  }
})
</script>

<style scoped lang="less">
.base-info-wrap {
  border-right: 1px solid var(--borderColor);
  padding-right: 20px;

  .top {
    color: rgba(80, 80, 80, 1);
    background-color: rgba(231, 189, 137, 1);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 104px;

    .avatar {
      i {
        font-size: 50px;
      }

      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }

      margin-right: 20px;
    }

    .name {
      .nick-name {
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        font-weight: bold;
      }

      .grade {
        padding: 2px 6px;
        color: rgba(255, 255, 255, 1);
        background-color: rgba(153, 101, 34, 1);
        margin-top: 10px;
        height: 27px;
        line-height: 27px;
        padding: 0 15px;
        border-radius: 16px;
        font-size: 14px;
      }
    }
  }

  .consume-info,
  .base-info,
  .tag {
    .title {
      position: relative;
      padding-left: 14px;
      margin: 20px 0;
      display: flex;
      justify-content: space-between;
      color: rgba(80, 80, 80, 1);
      font-size: 12px;

      .right {
        color: rgba(42, 130, 228, 1);
        font-size: 12px;
        cursor: pointer;
      }

      &::before {
        position: absolute;
        content: "";
        width: 4px;
        height: 22px;
        color: rgba(80, 80, 80, 1);
        background-color: rgba(42, 130, 228, 1);
        top: -2px;
        left: 0;
      }
    }
  }

  .consume-info {
    .consume-info-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .consume-info-item {
        width: 50%;
        margin: 15px 0;

        .left {
          color: rgba(128, 128, 128, 1);
          font-size: 12px;
          margin-bottom: 6px;
        }

        .right {
          color: rgba(80, 80, 80, 1);
          font-size: 16px;
          font-weight: bold;

          span {
            margin-right: 10px;
          }

          span:nth-child(2) {
            cursor: pointer;
            color: rgba(42, 130, 228, 1);
            font-size: 12px;
          }
        }
      }
    }
  }

  &:deep(.border1) {
    margin: 10px 0 26px 0 !important;
  }

  .base-info {
    .base-info-item {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
      color: rgba(128, 128, 128, 1);
      font-size: 12px;
      line-height: 22px;
    }
  }

  .tag {
    .title {
      span {
        margin-left: 10px;
      }
    }

    .tag-item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        color: rgba(128, 128, 128, 1);
        font-size: 12px;
      }

      .right {
        font-size: 12px;

        span {
          color: rgba(255, 255, 255, 1);
          background-color: rgba(0, 0, 0, 1);
          border-right: 1px solid white;
          font-size: 12px;
          display: inline-block;
          height: 28px;
          line-height: 28px;
          padding: 0 6px;
        }
      }
    }
  }
}
</style>
